

<div *ngIf="isDesktop" class="footer-desktop">
    <!--    <div class="footer" [style.justify-content]=" language.code === 'en' ? 'center' : 'flex-end'">-->
    <div class="footer" style="justify-content:center">
        <div class="center-container" style="margin-right: 30px;">
            <div class="footer-text" style="margin-top:19px; margin-right:0;">
                {{'footer.copyright_part1'|translate}}&nbsp;<span>&trade;</span>&nbsp; &#124;&nbsp;
                <u class="cursor-pointer custom-link" (click)="contactUs()"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>&nbsp;{{'footer.copyright_part2' | translate}}</u>&nbsp;&nbsp; &#124;&nbsp;
                <u class="cursor-pointer custom-link" (click)="techSupport()"><i class="fas fa-headset" style="margin-right: 5px;"></i>&nbsp;{{'footer.copyright_part3' | translate}}</u>&nbsp;&nbsp;&#124;&nbsp;
                <u class="cursor-pointer custom-link"(click)="showTermsAndConditions()"><i class="fas fa-balance-scale" style="margin-right: 5px;"></i>  {{'footer.copyright_part4' | translate}}</u>&nbsp;&nbsp;&#124;&nbsp;
                <u class="cursor-pointer custom-link"(click)="showPrivacyPolicy()"><i class="fas fa-user-shield" style="margin-right: 5px;"></i>  {{'footer.copyright_part9' | translate}}</u>&nbsp;&nbsp;&#124;&nbsp;
                <u class="cursor-pointer custom-link" (click)="showFAQ()"><i class="fas fa-question" style="margin-right: 5px;"></i> {{'footer.copyright_part8' | translate}}&nbsp;</u>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isDesktop" class="footer-mobile" style="margin-right:10px;">
    <div class="footer" [style.justify-content]=" language.code === 'en' ? 'center' : 'flex-end'">
<!--        <div class="sponsor-container" style="margin-left:0;">-->
<!--            <div class="sponsor-text" style="margin-left:10px; margin-right:0; margin-bottom:4px;">{{'msg.sponsoredBy' | translate}}</div>-->
<!--            <img style="width:91px; height:15px; margin-bottom:6px;  margin-left:0px;"-->
<!--                 [src]="'/assets/opop/T_foundation_' +-->
<!--                 (language.code === 'en' ? 'en' :-->
<!--                 (language.code === 'mk' ? 'mk' : 'al')) + '.jpg'"-->
<!--                 alt="Telekom Foundation"-->
<!--                 class="sponsor-logo">-->
<!--        </div>-->

        <div class="center-container" style="margin-right: 0; margin-left:auto;">
            <div class="footer-text" style="margin-top:18px; margin-left:auto; margin-right:10px;">
                <!--                {{'footer.copyright_part1'|translate}}&nbsp; &#124;&nbsp;-->
                <!--                <u class="cursor-pointer" (click)="contactUs()"><i class="pi pi-send"></i>&nbsp;{{'footer.copyright_part2' | translate}}</u>&nbsp; &#124;&nbsp;-->
                <!--                <u class="cursor-pointer" (click)="techSupport()"><i class="pi pi-cog"></i>&nbsp;{{'footer.copyright_part3' | translate}}</u>&nbsp;&#124;&nbsp;-->
                <u class="cursor-pointer" style="  margin-left:auto; margin-right:10px;" (click)="showTermsAndConditions()">{{'footer.copyright_part4' | translate}}</u>&nbsp; &nbsp;
                <u class="cursor-pointer custom-link"(click)="showPrivacyPolicy(true)"><i class="fas fa-user-shield" style="margin-right: 5px;"></i>  {{'footer.copyright_part9' | translate}}</u>&nbsp;&nbsp;&#124;&nbsp;

                <!--                <u class="cursor-pointer" (click)="showFAQ()">{{'footer.copyright_part8' | translate}}&nbsp;</u>-->
            </div>
        </div>
    </div>
</div>

