import {Component, HostListener} from '@angular/core';
import {DialogService} from "primeng/dynamicdialog";
import {TermsFAQComponent} from "./terms-faq.component";
import {TermsAndConditionsComponent} from './terms-conditions.component';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../generics/language.service';
import {Language} from '../generics/language.service';
import {Subscription } from 'rxjs';
import {MailOutComponent} from "../mail-out/mail-out.component";
import {PrivacyPolicy} from "./privacy-policy.component";


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent {
    language: Language;
    isDesktop: boolean = true;
    private langSubscription: Subscription;
    constructor(private dialogService: DialogService ,
                private router: Router, private sanitizer: DomSanitizer,
                private translate: TranslateService,
                private languageService: LanguageService) {
        this.onResize();
    }
    showTermsAndConditions() {
        const translatedHeader = this.translate.instant('footer.termsConditions');
        console.log("showTermsAndConditions");

        this.dialogService.open(TermsAndConditionsComponent, {width: '800px'})
    }

    showPrivacyPolicy(inDialog: boolean = true) {
        // console.log("showPrivacyPolicy");
        // this.dialogService.open(PrivacyPolicy, {width: '800px'})
        const translatedHeader = this.translate.instant('footer.privacyPolicy');
        console.log("showPrivacyPolicy");

        if (inDialog) {
            this.dialogService.open(PrivacyPolicy, {width: '800px'});
        } else {
            this.router.navigate(['/privacy-policy']); // Navigate to the URL directly
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    ngOnInit(): void {
        this.langSubscription = this.languageService.getSelectedLanguage().subscribe(lang => {
            this.language = lang;
        });
    }
    ngOnDestroy(): void {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }



    showFAQ() {
        this.dialogService.open(TermsFAQComponent, {width: '800px'})
    }

    getTrustedHtml(key: string): any {
        const value = this.translate.instant(key);
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }
    contactUs() {
        // this.router.navigate(['/send-email', { email:'info@opop.mk' }]);
        this.dialogService.open(MailOutComponent, {
            header: 'Contact Us', // Optional: set a header title
            width: '800px',
            data: {
                email: 'info@komuniti.mk' // Pass data to the component if needed
            }
        });
    }

    techSupport() {
        // this.router.navigate(['/send-email', { email:'support@opop.mk' }]);
        this.dialogService.open(MailOutComponent, {
            header: 'Contact Us', // Optional: set a header title
            width: '800px',
            data: {
                email: 'support@komuniti.mk' // Pass data to the component if needed
            }
        });
    }
}



