<div class="card"
     style="
     display:flex;
     justify-content: center;
     margin-top: 0;
     padding-left:20%;
     padding-right:20%;
     background:var(--bluegray-900)"
>
<!--    <span [innerHTML]="'footer.privacyPolicyText' | translate"></span>-->
    <h1>Privacy Policy</h1>
</div>

<style>
    @media (max-width: 976px) {
        .card {
            padding-left: 20px !important;
            padding-right: 30px !important;
        }
    }
</style>
